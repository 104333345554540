// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import { createPopper } from '@popperjs/core'
import * as bootstrap from "bootstrap"

//== POPOVER
document.addEventListener("turbo:load", function() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
});
