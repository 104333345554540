// Wird zur Zeit nicht verwendet

import { Controller } from "@hotwired/stimulus"
import {post} from "@rails/request.js"

//== JQUERY
import jQuery from "jquery";
// NOTE: make jQuery global if needed
window.$ = window.jQuery = jQuery;

export default class extends Controller {
    static targets = [ "solution"]
    static values = {
        wrong: String,
        url: String,
        id: Number
    }


    send_paper() {
        let paper_id = this.idValue
        let wrong_answer = "wrong_" + paper_id

        let solution_letters = Array.from(document.querySelectorAll('.solution-letter'))
        let solution = ''

        if (solution_letters.length > 0) {
            solution_letters.forEach(function(el) {
                solution = solution + el.value
            });
        }


        const passed = post(this.urlValue, {
            body: {paper_id: paper_id, solution: solution},
            responseKind: "json"
        })
            .then((response) => response.json)
            .then((data) => {
                let passed = data.passed
                if (passed === 'nenene') {
                    console.log(wrong_answer)
                    $("#"+wrong_answer).removeClass("d-none")
                }
                else {
                    let paper_button = "button_"+paper_id
                    $("#"+paper_button).html('<div class="btn-circle-A">Gut gemacht</div>');
                    $("#"+wrong_answer).addClass("d-none")
                    if (passed === 'woah') {
                        $("#bananas_count").html(data.current_bananas)
                        $("#answer_bananas").html('3 Bananen')
                        $("#answer_type").html('zum <strong>Titelthema</strong>')
                        $("#collapseCorrectAnswer").show()
                        $("#collapseSuperAnswer").show()
                    }
                }

            });

    } // send_paper


    send_article() {
        let article_id = this.idValue
        let wrong_answer = "wrong_" + article_id

        let solution_letters = Array.from(this.solutionTarget.querySelectorAll('.solution-letter'))
        let solution = ''

        if (solution_letters.length > 0) {
            solution_letters.forEach(function(el) {
                solution = solution + el.value
            });
        }


        const passed = post(this.urlValue, {
            body: {article_id: article_id, solution: solution},
            responseKind: "json"
        })
            .then((response) => response.json)
            .then((data) => {
                let passed = data.passed
                if (passed === 'nenene') {
                    console.log(wrong_answer)
                    $("#"+wrong_answer).removeClass("d-none")
                }
                else {
                    let article_button = "button_"+article_id
                    $("#"+article_button).html('<div class="btn-circle-A">Gut gemacht</div>');
                    $("#"+wrong_answer).addClass("d-none")
                    if (passed === 'woah') {
                        $("#answer_bananas").html(data.bananas)
                        $("#answer_type").html('zu den <strong>Kurzmeldungen</strong>')
                        $("#collapseCorrectAnswer").show()
                    }
                }

            });

    } // send_article


    send_riddle() {
        let riddle_id = this.idValue
        let wrong_answer = "wrong_" + riddle_id

        let solution_letters = Array.from(this.solutionTarget.querySelectorAll('.solution-letter'))
        let solution = ''

        if (solution_letters.length > 0) {
            solution_letters.forEach(function(el) {
                solution = solution + el.value
            });
        }

        const passed = post(this.urlValue, {
            body: {riddle_id: riddle_id, solution: solution},
            responseKind: "json"
        })
            .then((response) => response.json)
            .then((data) => {
                let passed = data.passed
                if (passed === 'nenene') {
                    console.log(wrong_answer)
                    $("#"+wrong_answer).removeClass("d-none")
                }
                else {
                    let riddle_button = "button_"+riddle_id
                    $("#"+riddle_button).html('<div class="btn-circle-A">Gut gemacht</div>');
                    $("#"+wrong_answer).addClass("d-none")
                }

            });

    } // send_riddle

}
